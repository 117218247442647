import clsx from 'clsx'
import { useRouter } from 'next/router'
import type { FunctionComponent } from 'react'
import { LogoListLabelType } from '../../generated/content'
import type { LogoListResult } from '../data/content/LogoListFragment'
import { ContemberImage } from './ContemberImage'
import { ImageSizeByArea } from './ImageSizeByArea'
import styles from './LogoList.module.sass'

export type LogoListProps = LogoListResult & {
	baseSize?: number
	imageMaxArea?: number
	isStripe?: boolean
	view?: 'default' | 'box' | 'bannerLogos' | 'strip'
}

export const LogoList: FunctionComponent<LogoListProps> = ({
	items,
	maxLogosInRow = 7,
	labelType,
	baseSize,
	imageMaxArea,
	view = 'default',
}) => {
	const { asPath } = useRouter()

	return (
		<div style={{ maxWidth: 'auto' }} className={styles.wrapper}>
			<div className={styles.main}>
				<div
					className={clsx(
						styles.list,
						labelType === LogoListLabelType.labelNextToIcon && styles.is_labelNextToIcon,
						styles[`is_${view}`]
					)}
					style={{ '--LogoList-list-maxColumns': maxLogosInRow }}>
					{items.map((item) => (
						<div
							key={item.id}
							className={clsx(styles.item, item.logoItem?.image?.alt && styles.is_withDescription)}
							style={{
								'--LogoList-item-width': item.logoItem?.image?.width && item.logoItem?.image?.width,
								'--LogoList-item-height': item.logoItem?.image?.height,
							}}>
							{item.logoItem?.image && (
								<figure className={styles.figure}>
									{view === 'strip' ? (
										item.logoItem.image.alt ? (
											<div className={styles.figureIn}>
												<ContemberImage
													image={item.logoItem.image}
													layout="fill"
													objectFit="contain"
												/>
											</div>
										) : (
											<ContemberImage
												image={item.logoItem.image}
												layout="fill"
												objectFit="contain"
											/>
										)
									) : (
										<ImageSizeByArea
											image={item.logoItem.image}
											baseSize={baseSize}
											imageMaxArea={imageMaxArea}
										/>
									)}
									{item.logoItem.image.alt && (
										<figcaption className={styles.figureCaption}>
											{item.logoItem.image.alt}
										</figcaption>
									)}
								</figure>
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
