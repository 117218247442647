import { useState, type FunctionComponent } from 'react'

import clsx from 'clsx'
import Image from 'next/image'
import { EmbedTabsBlockItemResult } from '../data/content/EmbedTabsItemFragment'
import { iframeSandbox } from '../utilities/iframeSandbox'
import style from './EmbedTabs.module.sass'

export const EmbedTabs: FunctionComponent<any> = ({ embedItems }) => {
	const [selectedTab, setSelectedTab] = useState<number>(0)

	return (
		<div className={style.wrapper}>
			<div className={style.tabContainer}>
				{embedItems.items &&
					embedItems.items.map((item: EmbedTabsBlockItemResult, index: number) => (
						<div
							key={item.id}
							className={clsx(style.tab, `${selectedTab === index ? style.activeTab : ''}`)}
							onClick={() => setSelectedTab(index)}>
							<Image
								alt={item.logo?.alt || (item.logo?.fileName as string)}
								src={item.logo?.url as string}
								width={item.logo?.width}
								height={item.logo?.height}
								className={style.logo}
							/>
						</div>
					))}
			</div>

			<div
				className={style.embedContainer}
				style={{
					'--Frame-iframe-aspectRatio': embedItems.items[selectedTab].primaryDoubleNumber,
					'--Frame-iframe-aspectRatioMobile': embedItems.items[selectedTab].secondaryDoubleNumber,
				}}>
				{embedItems.items[selectedTab] && (
					<iframe
						src={embedItems.items[selectedTab].frameUrl}
						className={style.iframe}
						sandbox={iframeSandbox}
						frameBorder={0}
						loading={selectedTab === 0 ? 'eager' : 'lazy'}
					/>
				)}
			</div>
		</div>
	)
}
