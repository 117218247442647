import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { ImageFragment } from './ImageFragment'


export const TileSliderFragment = () =>
	ZeusSelect<ValueTypes['TileSlider']>()({
		items: [{ orderBy: [{ order: OrderDirection.asc }]}, {
			title: true,
			description: true,
			image: ImageFragment()
		}],
	})

export type TileSliderResult = MapType<
	ModelTypes['TileSlider'],
	ReturnType<typeof TileSliderFragment>
>
