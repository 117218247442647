import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { IconFragment } from './IconFragment'

export const DescriptionTileItemFragment = () =>
	ZeusSelect<ValueTypes['DescriptionTileItem']>()({
		description: true,
		icon: [{}, IconFragment()],
	})

export type DescriptionTileItemFragmentResult = MapType<
	ModelTypes['DescriptionTileItem'],
	ReturnType<typeof DescriptionTileItemFragment>
>
