import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { AccordionFragment } from './AccordionFragment'
import { DescriptionTileFragment } from './DescriptionTileFragment'

export const AsideContentFragment = () =>
	ZeusSelect<ValueTypes['AsideContent']>()({
		isAccordion: true,
		accordion: [{}, AccordionFragment()],
		isAsideMediaOutOfLayout: true,
		isShapeInBackground: true,
		isFormBox: true,
		formAlignment: true,
		descriptionTile: [{}, DescriptionTileFragment()],
	})

export type AsideContentResult = MapType<
	ModelTypes['AsideContent'],
	ReturnType<typeof AsideContentFragment>
>
