import {
	MapType,
	ModelTypes,
	OrderDirection,
	ValueTypes,
	ZeusSelect,
} from '../../../generated/content'
import { AppTimelineFragment } from './AppTimelineFragment'
import { AsideContentFragment } from './AsideContentFragment'
import { BannerFeaturesFragment } from './BannerFeaturesFragment'
import { BannerFragment } from './BannerFragment'
import { BannerTestimonialsFragment } from './BannerTestimonialsFragment'
import { BoxFragment } from './BoxFragment'
import { ButtonFragment } from './ButtonFragment'
import { CaseStudyListFragment } from './CaseStudyListFragment'
import { ContentReferenceNewsItemFragment } from './ContentReferenceNewsItemFragment'
import { ContentReferenceResourceCategoryFragment } from './ContentReferenceResourceCategoryFragment'
import { ContentReferenceResourceFragment } from './ContentReferenceResourceFragment'
import { EmbedTabsFragment } from './EmbedTabsFragment'
import { ExtensionListFragment } from './ExtensionListFragment'
import { FancyTitleFragment } from './FancyTitleFragment'
import { FeatureTileListFragment } from './FeatureTileListFragment'
import { FormFragment } from './FormFragment'
import { GridBoxListFragment } from './GridBoxListFragment'
import { GridCardListFragment } from './GridCardListFragment'
import { HeroFragment } from './HeroFragment'
import { ImageCarouselFragment } from './ImageCarouselFragment'
import { ImageFragment } from './ImageFragment'
import { ImageHoverListFragment } from './ImageHoverListFragment'
import { InitiativesListFragment } from './InitiativesListFragment'
import { InteractionBlockListFragment } from './InteractionBlockListFragment'
import { KeywordsTileFragment } from './KeywordsTileFragment'
import { LinkFragment } from './LinkFragment'
import { LinkListFragment } from './LinkListFragment'
import { LoginPageFragment } from './LoginPageFragment'
import { LogoListFragment } from './LogoListFragment'
import { MediumListFragment } from './MediumListFragment'
import { NewsroomFragment } from './NewsroomFragment'
import { OurValuesListFragment } from './OurValuesListFragment'
import { PricingFAQListFragment } from './PricingFAQListFragment'
import { PricingPlansFragment } from './PricingPlansFragment'
import { ProductListFragment } from './ProductListFragment'
import { ResourceFragment } from './ResourceFragment'
import { RevealableMediaContentListFragment } from './RevealableMediaContentListFragment'
import { TabsWithMediaListFragment } from './TabsWithMediaListFragment'
import { TabWithRevealableMediaContentListFragment } from './TabWithRevealableMediaContentListFragment'
import { TextListFragment } from './TextListFragment'
import { TileSliderFragment } from './TileSliderFragment'
import { VideoFragment } from './VideoFragment'
import { VideoSelectFragment } from './VideoSelectFragment'

export const contentBlockReferenceNewsPerPage = 6

export const ContentBlockReferencesBaseFragment = (
	currentNewsPage: number,
	referenceResourceCategorySlug: string | null
) =>
	ZeusSelect<ValueTypes['ContentReference']>()({
		id: true,
		type: true,
		alignment: true,
		// @TODO: remove videoType
		videoType: true,
		primaryText: true,
		secondaryText: true,
		terciaryText: true,
		primaryNumber: true,
		primaryDoubleNumber: true,
		secondaryDoubleNumber: true,
		link: [{}, LinkFragment()],
		sublink: [{}, LinkFragment()],
		background: true,
		featureTiles: [{}, FeatureTileListFragment()],
		logoList: [{}, LogoListFragment()],
		productList: [{}, ProductListFragment()],
		keywordsTile: [{}, KeywordsTileFragment()],
		hero: [{}, HeroFragment()],
		box: [{}, BoxFragment()],
		media: [{}, MediumListFragment()],
		banner: [{}, BannerFragment()],
		icons: [{}, { icon: [{}, ImageFragment()] }],
		image: [{}, ImageFragment()],
		paginateNews: [
			{
				first: contentBlockReferenceNewsPerPage,
				skip: (currentNewsPage - 1) * contentBlockReferenceNewsPerPage,
				orderBy: [
					{
						item: {
							publishedAt: OrderDirection.descNullsLast,
						},
					},
				],
				// @TODO: filter out news in future
			},
			{
				edges: {
					node: ContentReferenceNewsItemFragment(),
				},
				pageInfo: {
					totalCount: true,
				},
			},
		],
		newsroom: [{}, NewsroomFragment()],
		textList: [{}, TextListFragment()],
		// @TODO: remove youtube
		youtube: [{}, { videoId: true }],
		// @TODO: remove wistiaUrl
		wistiaUrl: true,
		// @TODO: remove video
		video: [{}, VideoFragment()],
		videoSelect: [{}, VideoSelectFragment()],
		bannerFeatures: [{}, BannerFeaturesFragment()],
		pricingBannerFeatures: [{}, BannerFeaturesFragment()],
		bannerTestimonials: [{}, BannerTestimonialsFragment()],
		gridCardList: [{}, GridCardListFragment()],
		gridBoxList: [{}, GridBoxListFragment()],
		caseStudyList: [{}, CaseStudyListFragment()],
		appTimeline: [{}, AppTimelineFragment()],
		asideContent: [{}, AsideContentFragment()],
		form: [{}, FormFragment()],
		isFormWaitlist: true,
		resources: [
			{
				orderBy: [
					{
						item: {
							order: OrderDirection.asc,
						},
					},
				],
				filter:
					referenceResourceCategorySlug === null
						? undefined
						: {
								item: {
									categories: {
										slug: {
											eq: referenceResourceCategorySlug,
										},
									},
								},
						  },
			},
			ContentReferenceResourceFragment(),
		],
		resourceCategories: [
			{
				orderBy: [
					{
						item: {
							order: OrderDirection.asc,
						},
					},
				],
			},
			ContentReferenceResourceCategoryFragment(),
		],
		resourceBox: [{}, ResourceFragment()],
		frameUrl: true,
		extensionList: [{}, ExtensionListFragment()],
		button: [{}, ButtonFragment()],
		visualLineType: true,
		revealableMediaContentList: [{}, RevealableMediaContentListFragment()],
		tabWithRevealableMediaContentList: [{}, TabWithRevealableMediaContentListFragment()],
		tabsWithMediaList: [{}, TabsWithMediaListFragment()],
		fancyTitle: [{}, FancyTitleFragment()],
		hideOnDesktop: true,
		hideOnMobile: true,
		imageHoverRevealList: [{}, ImageHoverListFragment()],
		interactionBlockList: [{}, InteractionBlockListFragment()],
		ourValuesList: [{}, OurValuesListFragment()],
		asideMediaColor: true,
		headingOne: true,
		headingTwo: true,
		addPointingArrow: true,
		hideDesriptionOnDesktop: true,
		initiativesList: [{}, InitiativesListFragment()],
		loginPageBlockList: [{}, LoginPageFragment()],
		pricingPlansBlock: [{}, PricingPlansFragment()],
		fAQPricingList: [{}, PricingFAQListFragment()],
		mobileImage: [{}, ImageFragment()],
		linkBlock: [{}, LinkListFragment()],
		rounded: true,
		imageCarousel: [{}, ImageCarouselFragment()],
		featuredBlogOne: [{}, ResourceFragment()],
		featuredBlogTwo: [{}, ResourceFragment()],
		embedTabsBlock: [{}, EmbedTabsFragment()],
		tileSlider: [{}, TileSliderFragment()],
		showRating: true,
	})

export type ContentBlockReferencesBaseResult = MapType<
	ModelTypes['ContentReference'],
	ReturnType<typeof ContentBlockReferencesBaseFragment>
>
