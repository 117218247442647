import { useState, type FunctionComponent } from 'react'
import type { PricingFAQResult } from '../data/content/PricingFAQFragment'
import type { PricingFAQTileItemResult } from '../data/content/PricingFAQTileItemFragment'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'
import { Container } from './Container'
import { PricingAccordion } from './PricingAccordion'
import styles from './PricingFAQ.module.sass'

export type PricingFAQProps = PricingFAQResult & {
	primaryText: string
	id: string | number
	title: string
	items: [
		{
			id: string | number
			tile: {
				id: number
				description: string
				name: string
			}
		}
	]
}

export const PricingFAQ: FunctionComponent<PricingFAQTileItemResult & PricingFAQProps> = ({
	...props
}) => {
	const [expandedIndex, setExpandedIndex] = useState<number | null>(null)

	const handleAccordionClick = (index: number) => {
		setExpandedIndex(index === expandedIndex ? null : index)
	}
	return (
		<Container size="normal">
			<div className={styles.wrapper}>
				{props.primaryText && (
					<div className={styles.textContainer}>
						<div className={styles.textBox}>
							<h2 className={styles.title}>
								<CerosRichTextRenderer source={props.primaryText} />
							</h2>
						</div>
					</div>
				)}
				{/* {props.items && (
					<div className={styles.accordionsContainer}>
						{props?.items.map((i) => {
							return (
								<PricingAccordion title={i.tile.name}>
									<p className={styles.accordionDescription}>
										<CerosRichTextRenderer source={i.tile.description} />
									</p>
								</PricingAccordion>
							)
						})}
					</div>
				)} */}
				<div className={styles.accordionsContainer}>
					{props.items &&
						props.items.map((item, index) => (
							<PricingAccordion
								key={index}
								title={item.tile.name}
								expanded={index === expandedIndex}
								onClick={() => handleAccordionClick(index)}
								children={item.tile.description}>
								{/* Content for each accordion */}
							</PricingAccordion>
						))}
				</div>
			</div>
		</Container>
	)
}
