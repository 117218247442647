import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { DescriptionTileItemFragment } from './DescriptionTileItemFragment'

export const DescriptionTileFragment = () =>
	ZeusSelect<ValueTypes['DescriptionTile']>()({
		items: [{}, DescriptionTileItemFragment()],
	})

export type DescriptionTileResult = MapType<
	ModelTypes['DescriptionTile'],
	ReturnType<typeof DescriptionTileFragment>
>
