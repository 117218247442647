import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { EmbedTabsItemFragment } from './EmbedTabsItemFragment'

export const EmbedTabsFragment = () =>
	ZeusSelect<ValueTypes['EmbedTabsBlock']>()({
		items: [{}, EmbedTabsItemFragment()],
	})

export type AccordionResult = MapType<
	ModelTypes['EmbedTabsBlock'],
	ReturnType<typeof EmbedTabsFragment>
>
