import { MapType, ModelTypes, ValueTypes, ZeusSelect } from '../../../generated/content'
import { ImageFragment } from './ImageFragment'

export const EmbedTabsItemFragment = () =>
	ZeusSelect<ValueTypes['EmbedTabsBlockItem']>()({
		id: true,
		frameUrl: true,
		primaryDoubleNumber: true,
		secondaryDoubleNumber: true,
		logo: [{}, ImageFragment()],
	})

export type EmbedTabsBlockItemResult = MapType<
	ModelTypes['EmbedTabsBlockItem'],
	ReturnType<typeof EmbedTabsItemFragment>
>
