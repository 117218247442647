import clsx from 'clsx'
import { Collapsible } from 'collapsible-react-component'
import Image from 'next/image'
import type { Dispatch, FunctionComponent, SetStateAction } from 'react'
import type { AccordionItemResult } from '../data/content/AccordionItemFragment'
import styles from './AsideContentAccordionItem.module.sass'
import { CerosRichTextRenderer } from './CerosRichTextRenderer'

export type AsideContentAccordionItemProps = AccordionItemResult & {
	isItemOpenId: string | null
	setItemOpenId: Dispatch<SetStateAction<string | null>>
}

export const AsideContentAccordionItem: FunctionComponent<AsideContentAccordionItemProps> = ({
	id,
	name,
	description,
	icon,
	isItemOpenId,
	setItemOpenId,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.main}>
				<div className={styles.header}>
					<button
						type="button"
						className={clsx(styles.button, isItemOpenId === id)}
						onClick={() => setItemOpenId(isItemOpenId === id ? null : id)}>
						<div className={styles.titleWrapper}>
							{icon?.icon && (
								<div className={styles.emojiWrapper}>
									<Image
										src={icon.icon.url as any}
										alt={icon.icon?.alt || (icon.icon?.fileName as any)}
										width={icon.icon?.width}
										height={icon.icon?.height}
										className={styles.emojiStyles}
									/>
								</div>
							)}

							{name}
						</div>
						<div className={styles.mark}>{isItemOpenId === id ? '—' : '+'}</div>
					</button>
				</div>
				<Collapsible open={isItemOpenId === id}>
					<div className={clsx(styles.body, isItemOpenId === id)}>
						<div className={styles.invisibleDiv}></div>
						{description && <CerosRichTextRenderer source={description} />}
					</div>
				</Collapsible>
			</div>
		</div>
	)
}
